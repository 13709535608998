import { InvoiceStatus } from "./model";
import Decimal from 'decimal.js';
import { InvoiceIntegrationModel } from "../InvoiceIntegrationModel";
export function fromDTO(isVendor, dto) {
  const status = isVendor ? dto.vendorStatus : dto.customerStatus;
  return {
    id: dto.id,
    netTerms: dto.netTerms,
    createdOn: dto.createdOn,
    issuedOn: dto.issuedOn,
    dueDate: dto.dueDate,
    customerId: dto.customerId,
    isRetryable: dto.isRetryable,
    pdfExists: dto.pdfExists,
    totalAmount: dto.totalAmount,
    paymentMethodStatus: dto.paymentMethodStatus,
    formattedNumber: dto.formattedNumber,
    status: status.type,
    approvalMethod: status.type === InvoiceStatus.RequiresPaymentApproval ? status.requiresPaymentApproval.approvalMethod : null,
    paymentFailure: status.type === InvoiceStatus.PaymentFailed ? {
      code: status.paymentFailed.failureCode,
      message: status.paymentFailed.message
    } : null,
    paymentStop: status.type === InvoiceStatus.PaymentStopped ? status.paymentStopped : null,
    paymentDisputed: status.type === InvoiceStatus.PaymentDisputed ? {
      code: status.paymentDisputed.failureCode,
      message: status.paymentDisputed.message
    } : null,
    processingAction: status.type === InvoiceStatus.PaymentActionRequired ? status.paymentActionRequired.processingAction : null,
    displayedStatus: dto.displayStatus,
    paymentStatus: isVendor ? dto.vendorPaymentDisplayStatus : dto.customerPaymentDisplayStatus,
    isZeroAmount: dto.isTotalAmountZero,
    integration: InvoiceIntegrationModel.fromScoutDTO(dto.integration),
    items: dto.items,
    relationship: dto.relationship,
    rawTotalAmount: new Decimal(dto.rawTotalAmount).div(100).toDP(2),
    rawDueDate: new Date(dto.rawDueDate),
    rawIssuedOn: new Date(dto.rawIssuedOn),
    rawCreatedOn: new Date(dto.createdOn),
    number: dto.number
  };
}