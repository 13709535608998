import { getAllowManualPaymentsChangeBullet, getAmendmentsApprovalMethodChangeBullet, getCoverCCFeesChangeBullet, getInvoicesApprovalMethodChangeBullet, getNetTermsChangeBullet, getServiceChangeBullets } from "../../../../business-logic/amendmentsLogic";
import { relationshipModel, serviceModel, timeModel } from "../../../../models";
export function getChangesSummary(relationship) {
  const changesSummaryMap = new Map();
  function addChange(change, autoApprovalDate, amendmentDate) {
    const amendmentDateAsTimestamp = amendmentDate.getTime();
    const existingEntry = changesSummaryMap.get(amendmentDateAsTimestamp);
    if (existingEntry) {
      existingEntry.changes.push(change);
    } else {
      changesSummaryMap.set(amendmentDateAsTimestamp, {
        autoApprovalDate,
        changes: [change]
      });
    }
  }
  relationship.services.forEach(service => {
    const {
      status
    } = service;
    if (serviceModel.isAwaitingApproval(status) || serviceModel.isAmended(status)) {
      const amendmentDate = serviceModel.isAwaitingApproval(status) ? status.awaitingApproval.addedOn : status.amended.amendedOn;
      const approvalMethod = serviceModel.approvalMethod(service);
      const autoApprovalDate = approvalMethod ? approvalMethod.autoApprovalDate() : null;
      const bullets = getServiceChangeBullets(service);
      bullets.forEach(b => addChange("".concat(service.name, " - ").concat(b.text), autoApprovalDate, amendmentDate));
    }
  });
  const updatedNetTerms = relationshipModel.updatedNetTerms(relationship);
  if (updatedNetTerms) {
    const bullet = getNetTermsChangeBullet(relationship, updatedNetTerms.value());
    bullet && addChange(bullet.text, updatedNetTerms.approvalMethod().autoApprovalDate(), timeModel.fromDatestampObject(updatedNetTerms.changedOn()));
  }
  const updatedAmendmentsApprovalMethod = relationshipModel.updatedAmendmentsApprovalMethod(relationship);
  if (updatedAmendmentsApprovalMethod) {
    const bullet = getAmendmentsApprovalMethodChangeBullet(relationship.amendmentsApprovalMethod, updatedAmendmentsApprovalMethod.value());
    bullet && addChange(bullet.text, updatedAmendmentsApprovalMethod.approvalMethod().autoApprovalDate(), timeModel.fromDatestampObject(updatedAmendmentsApprovalMethod.changedOn()));
  }
  const updatedInvoicesApprovalMethod = relationshipModel.updatedInvoicesApprovalMethod(relationship);
  if (updatedInvoicesApprovalMethod) {
    const bullet = getInvoicesApprovalMethodChangeBullet(relationship.invoicesApprovalMethod, updatedInvoicesApprovalMethod.value());
    bullet && addChange(bullet.text, updatedInvoicesApprovalMethod.approvalMethod().autoApprovalDate(), timeModel.fromDatestampObject(updatedInvoicesApprovalMethod.changedOn()));
  }
  const updatedAllowManualPayments = relationshipModel.updatedAllowManualPayments(relationship);
  if (updatedAllowManualPayments) {
    const bullet = getAllowManualPaymentsChangeBullet(relationship, updatedAllowManualPayments.value());
    bullet && addChange(bullet.text, updatedAllowManualPayments.approvalMethod().autoApprovalDate(), timeModel.fromDatestampObject(updatedAllowManualPayments.changedOn()));
  }
  const updatedCoverCCFees = relationshipModel.updatedIsVendorCoveringCCFee(relationship);
  if (updatedCoverCCFees) {
    const bullet = getCoverCCFeesChangeBullet(relationship, updatedCoverCCFees.value());
    bullet && addChange(bullet.text, updatedCoverCCFees.approvalMethod().autoApprovalDate(), timeModel.fromDatestampObject(updatedCoverCCFees.changedOn()));
  }
  const sorted = new Map([...changesSummaryMap].sort((_ref, _ref2) => {
    let [amendmentDateA] = _ref;
    let [amendmentDateB] = _ref2;
    if (amendmentDateA == null) return 1;
    if (amendmentDateB == null) return -1;
    return amendmentDateA - amendmentDateB;
  }));
  return Array.from(sorted).map(_ref3 => {
    let [amendmentDate, {
      changes,
      autoApprovalDate
    }] = _ref3;
    return {
      autoApprovalDate,
      amendmentDate: amendmentDate ? new Date(amendmentDate) : null,
      changes
    };
  });
}