// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h2d5S{display:flex;gap:8px;flex-direction:column;padding-top:16px;width:400px}.svRHT{width:278px}._hzEY{padding-left:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `h2d5S`,
	"menuItem": `svRHT`,
	"noServices": `_hzEY`
};
export default ___CSS_LOADER_EXPORT___;
