import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useIoContext, useStoreActions } from "../contexts";

/** Loads stripe, and sets a boolean flag in the store if loading fails. */
export const useLoadStripe = () => {
  const {
    reactStripe: {
      loadStripe
    }
  } = useIoContext();
  const [stripeObj, setStripeObj] = useState(null);
  const {
    setStripeLoadingFailed
  } = useStoreActions();
  useEffect(() => {
    loadStripe(window.stripePublicToken).then(setStripeObj).catch(err => {
      setStripeLoadingFailed(true);
      Sentry.captureException(err);
    });
  }, []);
  return stripeObj;
};