import { billableStateModel, serviceBillingModel } from "../../models";
import { sortAlphabetically } from "../../infra/utils";
import { BillClientDisabledReason } from "../BillClientModal/BillClientModal.utils";
export const filteredRelationshipManualServices = (services, controlData) => {
  return services.filter(service => {
    return serviceBillingModel.isManual(service.billing) && controlData.manualServices[service.id] && billableStateModel.isBillable(controlData.manualServices[service.id].billableState);
  });
};
export const mapRelationshipSummariesToAgreementOptions = relationshipSummaries => {
  const options = relationshipSummaries.map(summary => ({
    label: summary.title,
    value: summary.id
  }));
  return sortAlphabetically(options, 'label', {
    ascending: true,
    ignoreCase: true
  });
};
export const mapServicesToServiceOptions = (services, controlData) => {
  return filteredRelationshipManualServices(services, controlData).map(service => {
    return {
      name: service.name,
      costString: serviceBillingModel.formatedCost(service),
      id: service.id,
      disabledReason: {
        isDisabled: false,
        reasonTooltip: BillClientDisabledReason.none
      }
    };
  });
};
export const getServiceOptionSelected = (option, value) => option.id === value.id;
export const getServiceOptionLabel = option => option.name;