import { useMemo } from 'react';
import { isAfter, isBefore } from 'date-fns';
import { useIoContext } from "../../../providers/IoContext";
import { getDateRange, normalizeEffectiveDate } from "./BillingDate.utils";
export const useIssueDateValidation = _ref => {
  let {
    effectiveDate,
    earliestBillableDate,
    mode
  } = _ref;
  const {
    getNow
  } = useIoContext();
  const {
    minDate,
    maxDate
  } = useMemo(() => {
    return getDateRange(mode, getNow(), normalizeEffectiveDate(effectiveDate), earliestBillableDate);
  }, [mode, effectiveDate, earliestBillableDate]);
  const validationRules = {
    validate(value) {
      const isInvalid = !value || !!minDate && isBefore(value, minDate) || !!maxDate && isAfter(value, maxDate);
      return !isInvalid || 'Invalid date';
    }
  };
  return {
    validationRules,
    maxDate,
    minDate
  };
};