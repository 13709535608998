// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V5LcX{display:flex;flex-direction:column;align-items:center;justify-content:center;width:100%;height:100vh;gap:16px}.vE4_J{text-align:center;width:60%;max-width:1020px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `V5LcX`,
	"subtitle": `vE4_J`
};
export default ___CSS_LOADER_EXPORT___;
