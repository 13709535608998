import pluralize from 'pluralize';
import { IntegrationSource } from "../../types/api.model";
import { helpArticles } from "../../utils/helpArticles";
export const getPMDetails = pm => ({
  name: {
    [IntegrationSource.Karbon]: 'Karbon',
    [IntegrationSource.ClientHub]: 'Client Hub',
    [IntegrationSource.FinancialCents]: 'Financial Cents'
  }[pm],
  item: {
    [IntegrationSource.Karbon]: karbonItem,
    [IntegrationSource.ClientHub]: clientHubItem,
    [IntegrationSource.FinancialCents]: financialCentsItem
  }[pm],
  items: {
    [IntegrationSource.Karbon]: pluralize(karbonItem),
    [IntegrationSource.ClientHub]: pluralize(clientHubItem),
    [IntegrationSource.FinancialCents]: pluralize(financialCentsItem)
  }[pm],
  company: {
    [IntegrationSource.Karbon]: 'organization',
    [IntegrationSource.ClientHub]: 'organization',
    [IntegrationSource.FinancialCents]: 'client'
  }[pm],
  contact: {
    [IntegrationSource.Karbon]: 'contact',
    [IntegrationSource.ClientHub]: 'client',
    [IntegrationSource.FinancialCents]: 'contact'
  }[pm],
  template: {
    [IntegrationSource.Karbon]: 'work template',
    [IntegrationSource.ClientHub]: 'job template',
    [IntegrationSource.FinancialCents]: 'template'
  }[pm],
  articleLink: {
    [IntegrationSource.Karbon]: helpArticles.karbon(),
    [IntegrationSource.ClientHub]: helpArticles.clientHub(),
    [IntegrationSource.FinancialCents]: helpArticles.financialCents()
  }[pm],
  nameForTestID: {
    [IntegrationSource.Karbon]: 'karbon',
    [IntegrationSource.ClientHub]: 'client-hub',
    [IntegrationSource.FinancialCents]: 'financial-cents'
  }[pm]
});
const karbonItem = 'work item';
const clientHubItem = 'job';
const financialCentsItem = 'project';