function helpArticle(name) {
  return "https://help.sayanchor.com/en/articles/".concat(name);
}
function intercomHelpArticle(name) {
  return "https://intercom.help/anchor-billing/en/articles/".concat(name);
}
export const helpArticles = {
  howToEditAgreement: () => helpArticle('5095234-how-to-edit-an-agreement'),
  chargebacksAndDisputes: goto => {
    const suffix = goto === 'missing-auth' ? '#h_6c2de571af' : '';
    return helpArticle("8304718-chargebacks-and-payment-disputes-in-anchor".concat(suffix));
  },
  qboSetup: goto => {
    const suffix = goto === 'services-mapping' ? '#h_cae7f37e1c' : goto === 'contact-mapping' ? '#h_1492f2224f' : '';
    return helpArticle("6745076-setting-up-quickbooks-online".concat(suffix));
  },
  accountantAccess: {
    vendor: () => helpArticle('7998834-accountant-access'),
    customer: () => helpArticle('8095833-accountant-access')
  },
  qboReactivateItems: () => helpArticle('8385713-how-to-reactivate-items-in-quickbooks-online'),
  qboUnlinkPayment: () => helpArticle('8544439-how-to-unlink-a-payment-from-a-deposit-in-quickbooks-online'),
  qboAccountPeriodClosed: () => helpArticle('8676191-how-to-resolve-a-quickbooks-online-sync-error-for-a-closed-account-period'),
  onboardingExistingClients: () => helpArticle('10219710-best-practices-for-onboarding-existing-clients-to-anchor'),
  amendmentsRequiringApproval: () => helpArticle('7733302-what-amendments-must-the-client-approve'),
  refunds: () => helpArticle('8011138-refunding-client-payments'),
  achUnauthorizedFailures: () => helpArticle('6668991-can-i-minimize-the-potential-for-disputes-and-declined-payments-due-to-unauthorization-failures-ach'),
  referralProgram: () => helpArticle('8102891-what-is-anchor-s-referral-program'),
  proposalTemplates: () => helpArticle('8512622-proposal-templates'),
  notificationSettings: () => helpArticle('9729494-notification-settings'),
  requestPaymentMethod: () => helpArticle('8344568-about-request-payment-method'),
  anchorZaps: () => helpArticle('7833601-what-anchor-zaps-can-i-create'),
  karbon: () => helpArticle('9881648-karbon'),
  clientHub: () => helpArticle('9920686-client-hub'),
  financialCents: () => helpArticle('9881503-financial-cents'),
  kybProcessOverview: () => intercomHelpArticle('9938694-kyb-process-overview'),
  paymentsViaAnchor: () => intercomHelpArticle('5661179-payments-via-anchor'),
  kFormIssuance2024: () => intercomHelpArticle('10108142-1099-k-form-issuance-2024'),
  importClientsBulk: () => intercomHelpArticle('4848558-how-to-import-clients-in-bulk-using-a-csv-file'),
  connectQuickbooksAnchor: () => intercomHelpArticle('6867639-how-to-connect-quickbooks-to-anchor'),
  applyClientCredit: () => intercomHelpArticle('6118071-how-to-apply-a-credit-for-your-client')
};