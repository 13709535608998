import { selectors } from "../store/state-selectors";
import { useStore, useStoreActions } from "../store/store-context";
import { handleRequest } from "../infra/utils";
import { useState } from 'react';
import { relationshipSummaryModel } from "../models";
import { useOverlayContext } from 'seagull';
import { mapRelationshipSummariesToAgreementOptions } from "../components/GlobalBillModal/GlobalBillModal.utils";
import { GlobalBillModalErrorMessages } from "../components/GlobalBillModal/GlobalBillModal.types";
export const defaultAgreementStatusSelectorFilter = [relationshipSummaryModel.AgreementStatus.Active, relationshipSummaryModel.AgreementStatus.Exhausted];
export const useFetchContactAgreements = () => {
  const {
    api
  } = useStoreActions();
  const overlayContext = useOverlayContext();
  const businessId = useStore(selectors.user.businessId);
  const isVendor = useStore(selectors.user.isVendor);
  const [agreements, setAgreements] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchAgreements = async clientContactId => {
    if (!businessId) {
      return;
    }
    setLoading(true);
    const response = await handleRequest(api.relationship.getSummaries({
      businessId,
      isVendor,
      clientContactId,
      type: 'agreement',
      filters: {
        agreementStatus: defaultAgreementStatusSelectorFilter
      }
    }));
    setLoading(false);
    if (response.success) {
      const newAgreements = mapRelationshipSummariesToAgreementOptions(response.data);
      setAgreements(newAgreements);
      return newAgreements;
    } else {
      overlayContext.showErrorToast(GlobalBillModalErrorMessages.FailedToRequestAgreements, 'agreements-request-error-toast');
    }
  };
  return {
    fetchAgreements,
    agreements,
    loading,
    isOnlyOneAgreement: agreements.length === 1,
    areNoAgreements: agreements.length === 0
  };
};