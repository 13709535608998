import { routes } from "../../../constants";
import { SetLoginFlow } from "./LoginScreen";
export const getIsSimplifiedLogin = _ref => {
  let {
    NEW_LOGIN_FLOW,
    locationSearch,
    locationPathname,
    hasVendorAccount,
    setLoginFlow
  } = _ref;
  const clientView = NEW_LOGIN_FLOW ? new URLSearchParams(locationSearch).get('view') === 'client' : locationPathname === routes.redirect || new URLSearchParams(locationSearch).get('view') === 'client';
  if (setLoginFlow != null && NEW_LOGIN_FLOW) {
    return setLoginFlow === SetLoginFlow.Simplified;
  }

  // in case we didnt come from notification or no-access page and we have a cookie
  if (hasVendorAccount != null && NEW_LOGIN_FLOW) {
    return !hasVendorAccount;
  }
  return clientView;
};