import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { redirect, routes } from "../constants";
export function RedirectPage() {
  const history = useHistory();
  const {
    search
  } = useLocation();
  const returnTo = useMemo(() => redirect.getReturnToFromSearch(search), [search]);
  useEffect(() => {
    history.replace(returnTo || routes.home);
  }, [returnTo]);
  return null;
}