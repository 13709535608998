import * as relationshipModel from "./relationship";
import { RelationshipStatus } from "./relationship";
import { ServiceSource } from "./service/model";
export class AgreementDataModel {
  static make(data) {
    return new AgreementDataModel(data.netTerms, data.autoPayment, data.status, data.effectiveDate);
  }
  static fromRelationship(relationship) {
    return new AgreementDataModel(relationshipModel.actualNetTerms(relationship), !relationship.allowManualPayments, relationship.status, relationship.effectiveDate);
  }
  constructor(netTerms, autoPayment, status, effectiveDate) {
    this.netTerms = netTerms;
    this.autoPayment = autoPayment;
    this.status = status;
    this.effectiveDate = effectiveDate;
  }
  setNetTerms(netTerms) {
    return new AgreementDataModel(netTerms, this.autoPayment, this.status, this.effectiveDate);
  }
  isActiveAgreement() {
    return this.status !== RelationshipStatus.Pending;
  }
  serviceSource() {
    return this.isActiveAgreement() ? ServiceSource.Amendment : ServiceSource.Draft;
  }
}