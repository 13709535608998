import { QboStatusFilter } from "../../business-logic/qboStatusFilterLogic";
const sortByDepositDate = (a, b) => b.createdAt() - a.createdAt();
const sortById = (a, b) => a.id() < b.id() ? -1 : 1;
const sortByAmount = (a, b) => b.amount().sub(a.amount()).toNumber();
const sortFunctions = {
  0: isAscending => (a, b) => {
    const retval = sortByDepositDate(a, b) || sortById(a, b);
    return isAscending ? -retval : retval;
  },
  1: isAscending => (a, b) => {
    const retval = sortByAmount(a, b) || sortById(a, b);
    return isAscending ? -retval : retval;
  },
  2: isAscending => (a, b) => {
    // Status is always 'paid' so there's nothing to sort in this column.
    const retval = sortById(a, b);
    return isAscending ? -retval : retval;
  }
};
function applySearch(payouts, search) {
  const normalizedSearch = search ? search.trim().toLowerCase() : search;
  return payouts.filter(payout => {
    const searchWords = [];
    payout.invoices().forEach(invoice => {
      searchWords.push(invoice.formattedNumber);
      const clientName = invoice.customer;
      clientName && searchWords.push(clientName);
    });
    return searchWords.join(' ').toLowerCase().includes(normalizedSearch);
  });
}
function applyQboStatusFilter(payouts, qboStatusFilter) {
  switch (qboStatusFilter) {
    case QboStatusFilter.All:
      return payouts;
    case QboStatusFilter.Synced:
      return payouts.filter(p => p.isSynced());
    case QboStatusFilter.NotSynced:
      return payouts.filter(p => p.isNotSynced());
  }
}
export function applyFilters(_ref) {
  let {
    payouts,
    qboStatusFilter,
    search
  } = _ref;
  let filtered = payouts;
  filtered = applySearch(filtered, search);
  if (qboStatusFilter != null) {
    filtered = applyQboStatusFilter(filtered, qboStatusFilter);
  }
  return filtered;
}
export function applySort(payouts, sort) {
  if (sort) {
    payouts.sort(sortFunctions[sort.colIndex](sort.sort === 'asc'));
  }
  return payouts;
}
export function getQboStatusFilterOptions(payouts) {
  const options = [{
    value: QboStatusFilter.All,
    label: 'All',
    counter: payouts.length
  }, {
    value: QboStatusFilter.Synced,
    label: 'Synced',
    counter: 0
  }, {
    value: QboStatusFilter.NotSynced,
    label: 'Not synced',
    counter: 0
  }];
  payouts.forEach(payout => {
    payout.isSynced() && options[1].counter++;
    payout.isNotSynced() && options[2].counter++;
  });
  return options.filter(opt => opt.counter > 0);
}