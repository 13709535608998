export class Observable {
  constructor() {
    this.observers = [];
  }
  subscribe(subscriber) {
    this.observers.push(subscriber);
  }
  unsubscribe(id) {
    this.observers = this.observers.filter(obs => obs.id !== id);
  }
  notify(data) {
    this.observers.forEach(observer => observer.callback(data));
  }
}