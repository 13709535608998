import { NonBillableReason } from "../models/billable-state";
import { BillingType } from "../components/BillClientModal/BillClientModal.types";
import { billableStateModel, serviceBillingModel, serviceModel } from "../models";
import { BillClientDisabledReason } from "../components/BillClientModal/BillClientModal.utils";
import { sortAlphabetically } from "../infra/utils";
const isReachedMaxCharges = (service, serviceBillableState, items) => {
  const maxCharges = billableStateModel.maxCharges(serviceBillableState);
  const sameServiceItems = items.filter(item => item.type === BillingType.Service && item.service.id === service.id);
  return sameServiceItems.length === maxCharges;
};
const getBillClientModalDisabledReason = (service, items, controlData) => {
  const isAlreadyInList = items.some(item => item.type === BillingType.Service && item.service.id === service.id);
  const serviceBillableState = controlData.manualServices[service.id] ? controlData.manualServices[service.id].billableState : null;
  if (serviceBillingModel.isOneTimeManual(service.billing) && isAlreadyInList) {
    return {
      reasonTooltip: BillClientDisabledReason.oneTimeManualAlreadyInList,
      isDisabled: true
    };
  }
  if (serviceBillableState && isReachedMaxCharges(service, serviceBillableState, items)) {
    return {
      reasonTooltip: BillClientDisabledReason.billedMaxCharges,
      isDisabled: true
    };
  }
  return getNonBillableDisableReason(service, controlData);
};
export const getNonBillableDisableReason = (service, controlData) => {
  const serviceBillableState = controlData.manualServices[service.id] ? controlData.manualServices[service.id].billableState : null;
  if (serviceBillableState) {
    if (billableStateModel.nonBillableReason(serviceBillableState) === NonBillableReason.BilledMaxCharges) {
      return {
        reasonTooltip: BillClientDisabledReason.billedMaxCharges,
        isDisabled: true
      };
    }
    if (billableStateModel.nonBillableReason(serviceBillableState) === NonBillableReason.IssuingInvoice) {
      return {
        reasonTooltip: BillClientDisabledReason.issuingInvoice,
        isDisabled: true
      };
    }
  }
  return {
    reasonTooltip: BillClientDisabledReason.none,
    isDisabled: false
  };
};
export function filterServicesByStatusAndType(services) {
  return services.filter(service => serviceBillingModel.isManual(service.billing) && !serviceBillingModel.hasUnbillableStatus(service));
}
export function getBillClientServiceOptions(_ref) {
  let {
    items,
    services,
    controlData
  } = _ref;
  const filteredServices = sortAlphabetically(filterServicesByStatusAndType(services), 'name', {
    ascending: true,
    ignoreCase: true
  });
  return filteredServices.map(service => {
    return {
      id: service.id,
      name: service.name,
      costString: serviceBillingModel.formatedCost(service),
      disabledReason: getBillClientModalDisabledReason(service, items, controlData)
    };
  });
}
export function getGlobalBillServiceOptions(_ref2) {
  let {
    services,
    controlData
  } = _ref2;
  const filteredServices = sortAlphabetically(filterServicesByStatusAndType(services), 'name', {
    ascending: true,
    ignoreCase: true
  });
  return filteredServices.map(service => {
    return {
      id: service.id,
      name: service.name,
      costString: serviceBillingModel.formatedCost(service),
      disabledReason: getNonBillableDisableReason(service, controlData)
    };
  });
}
export function getTimeActivitiesServiceOptions(_ref3) {
  let {
    services,
    controlData
  } = _ref3;
  const filteredServices = services.filter(service => serviceModel.isSuitableForTimeActivities(service) && controlData.manualServices[service.id] && billableStateModel.isBillable(controlData.manualServices[service.id].billableState));
  if (!filteredServices.length) {
    return [];
  }
  const sortedServices = sortAlphabetically(filteredServices, 'name', {
    ascending: true,
    ignoreCase: true
  });
  return sortedServices.map(service => {
    return {
      id: service.id,
      name: service.name,
      costString: serviceBillingModel.formatedCost(service)
    };
  });
}