export function formatNumericUSDate(date) {
  return date.toLocaleString('en-us', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });
}
export function formatLongMonthUSDate(date) {
  let timeZone = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'UTC';
  return date.toLocaleString('en-us', {
    timeZone,
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}
export const formatShortMonthUSDate = date => date.toLocaleDateString('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric'
});