function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { format } from 'date-fns';
import { ServiceStatusType } from "../../../models/service/model";
import { LineItemType } from "../../../models/invoice-draft";
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import { invoiceDraftModel, relationshipModel, timeModel } from "../../../models";
import { makeInvoiceDateInfo } from "../../../business-logic/billingLogic";
export const testPrefix = 'billing-hub-';
export function dateToString(date) {
  let variant = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'default';
  if (variant === 'default') {
    return format(date, 'PPP');
  } else {
    // Formatting requirements: Jan, Feb, **March**, **April**, May, **June**, **July**, Aug, Sep, Oct, Nov, Dec
    const longFormatMonths = new Set([3, 4, 6, 7]);
    const monthFormat = longFormatMonths.has(date.getMonth() + 1) ? 'LLLL' : 'LLL';
    return format(date, "".concat(monthFormat, " do, y"));
  }
}
const unbillableServiceStatuses = [ServiceStatusType.Completed, ServiceStatusType.Terminated, ServiceStatusType.Canceled];

/** These Services can't be billed in the billing hub. */
export const isServiceRelevantForBillingHub = s => !unbillableServiceStatuses.includes(s.status.type);
export function getLineItemsProps(invoiceDraft, _onApprove, _onRemove) {
  const unsortedLineItems = invoiceDraft.lineItems.map(lineItem => {
    return {
      charge: getLineItemChargeProps(lineItem),
      onApprove: () => _onApprove(lineItem.id),
      onRemove: () => _onRemove(lineItem.id, {
        billLaterCommandId: invoiceDraftModel.billLaterCommandId(lineItem),
        serviceId: invoiceDraftModel.serviceId(lineItem)
      })
    };
  });
  return sortBy(unsortedLineItems, getSortingRank);
}
const lineItemTypeToRank = {
  [LineItemType.AdHocCharge]: 1,
  [LineItemType.Manual]: 2,
  [LineItemType.Automatic]: 3,
  [LineItemType.Credit]: 4
};
function getSortingRank(lineItemProps) {
  const typeRank = lineItemTypeToRank[lineItemProps.charge.type];
  return "".concat(typeRank).concat(lineItemProps.charge.name.toLowerCase()).concat(lineItemProps.charge.id);
}
function getLineItemChargeProps(lineItem) {
  const shouldHaveANegativeValue = invoiceDraftModel.isCredit(lineItem);
  const totalAmount = shouldHaveANegativeValue ? lineItem.totalAmount.negated() : lineItem.totalAmount;
  const rate = _objectSpread(_objectSpread({}, lineItem.rate), {}, {
    amount: shouldHaveANegativeValue ? lineItem.rate.amount.negated() : lineItem.rate.amount
  });
  return _objectSpread(_objectSpread({}, pick(lineItem, ['id', 'name', 'note'])), {}, {
    totalAmount,
    rate,
    type: lineItem.source,
    discount: invoiceDraftModel.discount(lineItem) || undefined,
    pendingApprovalReason: invoiceDraftModel.pendingApprovalReason(lineItem) || undefined,
    quantity: invoiceDraftModel.quantity(lineItem) || undefined
  });
}
export const getInvoiceDateInfo = (selectedDate, invoicesDraftDates) => selectedDate && invoicesDraftDates.dates().length > 0 ? makeInvoiceDateInfo(invoicesDraftDates, selectedDate) : undefined;
export function getEmptyState(relationship) {
  if (relationshipModel.isTerminated(relationship)) {
    return {
      text: 'This agreement has been terminated',
      paragraphs: []
    };
  } else if (relationshipModel.isPending(relationship)) {
    return {
      text: relationship.effectiveDate ? "This agreement will be in effect starting ".concat(timeModel.formatDate(relationship.effectiveDate)) : 'This agreement has not been signed yet',
      paragraphs: ["Once the agreement is in effect, you'll be able to view your upcoming bill here."]
    };
  } else {
    return null;
  }
}
export const getNoInvoicesText = (selectedDate, isVendor) => selectedDate ? "There is no invoice scheduled for this date.\nPlease choose a different date." : 'There are no scheduled invoices to show.' + (isVendor ? "\nClick on \"Billing Control\" at the top right to create invoices." : '');