import { ServerError } from "../../infra/ErrorResponse";
export const notificationSomethingWentWrongContent = {
  header: 'Something went wrong',
  notificationDescription: 'Your request could not be processed at this time. Please try again later or reach out to our support team at support@sayanchor.com'
};
export const getApproveInvoiceContent = _ref => {
  let {
    invoiceApproveResult,
    invoiceFormattedNumber,
    vendorName
  } = _ref;
  if (invoiceApproveResult.success) {
    return {
      header: 'Payment approved',
      notificationDescription: "Your payment for invoice ".concat(invoiceFormattedNumber, " issued by ").concat(vendorName, " has been approved and will be processed promptly.")
    };
  }
  if (invoiceApproveResult.error.hasServerError(ServerError.InvoiceAlreadyApproved)) {
    return {
      header: 'Payment already approved',
      notificationDescription: "The payment for invoice ".concat(invoiceFormattedNumber, " issued by ").concat(vendorName, " has already been approved. Sign in to review the invoice.")
    };
  }
  if (invoiceApproveResult.error.hasServerError(ServerError.InvoiceWasVoided)) {
    return {
      header: 'Payment could not be approved',
      notificationDescription: "Invoice ".concat(invoiceFormattedNumber, " issued by ").concat(vendorName, " has been voided and therefore its payment cannot be approved. Sign in to review the invoice.")
    };
  }
  return notificationSomethingWentWrongContent;
};
export const getApproveAmendmentContent = _ref2 => {
  let {
    vendorName,
    amendmentApproveResult
  } = _ref2;
  if (amendmentApproveResult.success) {
    return {
      header: 'Amendments approved',
      notificationDescription: "Your approval for the amendments to your agreement with ".concat(vendorName, " has been recorded.")
    };
  }
  if (amendmentApproveResult.error.hasServerError(ServerError.RelationshipAlreadyTerminated)) {
    return {
      header: 'Amendments could not be approved',
      notificationDescription: "Your agreement with ".concat(vendorName, " has been terminated. Please contact your service provider for more details.")
    };
  }
  if (amendmentApproveResult.error.hasServerError(ServerError.NoAmendmentsToApprove)) {
    return {
      header: 'No amendments to approve',
      notificationDescription: "There are no pending amendments to your agreement with ".concat(vendorName, ". Sign in to review the updated agreement.")
    };
  }
  if (amendmentApproveResult.error.hasServerError(ServerError.MismatchingRelationshipVersion)) {
    return {
      header: 'Amendments could not be approved',
      notificationDescription: "Additional changes have been made to your agreement with ".concat(vendorName, " since this approval link was generated. Please sign in to review the latest updates before proceeding with approval.")
    };
  }
  return notificationSomethingWentWrongContent;
};