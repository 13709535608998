// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UAvZy{margin-top:18px}.RExmG{margin-left:6px}.wRol5{white-space:pre-wrap}.iQkOn{padding:4px;margin:16px 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableTitle": `UAvZy`,
	"learnMoreLink": `RExmG`,
	"preserveLineBreak": `wRol5`,
	"mailCallout": `iQkOn`
};
export default ___CSS_LOADER_EXPORT___;
