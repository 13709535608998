export class TimeActivitiesModel {
  constructor() {
    let services = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    this.services = services;
  }
  static fromDTO(dto) {
    const services = {};
    dto.forEach(item => {
      services[item.serviceId] = item.timeActivities;
    });
    return new TimeActivitiesModel(services);
  }
  getServices() {
    return this.services;
  }
  getTimeActivitiesForService(serviceId) {
    return this.services[serviceId || ''] || [];
  }
  hasServices() {
    return Object.keys(this.services).length > 0;
  }
}