// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HCeKD{display:flex;align-items:center;justify-content:center;flex-direction:column;gap:16px;height:100%;padding:24px;text-align:center}.xSmop{max-width:1020px;text-align:center}.xD0GR{display:flex;justify-content:center;gap:18px;margin-top:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationNoAccessContainer": `HCeKD`,
	"description": `xSmop`,
	"buttonContainer": `xD0GR`
};
export default ___CSS_LOADER_EXPORT___;
