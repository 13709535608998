// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YujrB{display:flex;flex-direction:column;gap:8px}.CE3DU{margin-top:16px;width:390px;margin-left:30px}.AgOEj{display:flex;flex-direction:column}.JY_YB{display:flex;flex-direction:column;gap:24px}.whiDg{width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billingSelectorsWrapper": `YujrB`,
	"manualServicesSelector": `CE3DU`,
	"serviceOption": `AgOEj`,
	"globalBillModalContent": `JY_YB`,
	"serviceOptionContent": `whiDg`
};
export default ___CSS_LOADER_EXPORT___;
