import { useMemo } from 'react';
import { useIoContext } from "../../../providers/IoContext";
import { getBillModeOptions, getDefaultBillMode, getDefaultIssueDate, normalizeEffectiveDate } from "./BillingDate.utils";
export const useBillingModeData = _ref => {
  let {
    invoiceDateInfo,
    effectiveDate = null,
    earliestBillableDate
  } = _ref;
  const {
    getNow
  } = useIoContext();
  const billModeOptions = useMemo(() => {
    return getBillModeOptions(getNow(), effectiveDate, invoiceDateInfo);
  }, [effectiveDate, invoiceDateInfo]);
  const defaultMode = useMemo(() => getDefaultBillMode(billModeOptions, invoiceDateInfo), [billModeOptions, invoiceDateInfo]);
  const defaultIssueDate = useMemo(() => getDefaultIssueDate(defaultMode, invoiceDateInfo, getNow(), normalizeEffectiveDate(effectiveDate), earliestBillableDate), [defaultMode, invoiceDateInfo, effectiveDate, earliestBillableDate]);
  return {
    billModeOptions,
    defaultMode,
    defaultIssueDate
  };
};