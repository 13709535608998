import { RevenueStatus } from "../../../models/HistoricRevenueModel";
import colors from 'seagull/foundations/colors.module.scss';
const paidColor = colors.purple700;
const expectedColor = colors.dark200;
const paidExternallyColor = colors.purple300;
export const revenueStatusOrder = [RevenueStatus.Paid, RevenueStatus.PaidExternally, RevenueStatus.Expected];
export const colorsMap = {
  [RevenueStatus.Paid]: paidColor,
  [RevenueStatus.Expected]: expectedColor,
  [RevenueStatus.PaidExternally]: paidExternallyColor
};
export const labelMap = {
  [RevenueStatus.Paid]: 'Paid via Anchor',
  [RevenueStatus.Expected]: 'Expected',
  [RevenueStatus.PaidExternally]: 'Paid externally'
};
export const getLegendItems = data => revenueStatusOrder.filter(status => data.some(item => item[status].totalAmount.gt(0))).map(status => ({
  status,
  label: labelMap[status],
  color: colorsMap[status]
}));