function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import compact from 'lodash/compact';
import { DiscountModel, serviceBillingModel, serviceCostModel, serviceModel } from "../../models";
import { BillingTriggerType } from "../../types/api.model";
import { ServiceStatusType } from "../../models/service/model";
import { pluralizeUnitName } from "../../utils/unit-name.utils";
import { ServiceCostType } from "../../models/service-cost";
import { utils } from "../../utils";
import { isAfter, startOfDay } from 'date-fns';
import { fromDatestampObject } from "../../models/time";
export function getServiceDescriptionBadges(service, isActiveAgreement, agreementData, now) {
  return compact([billingDetailsBadge(service, isActiveAgreement), discountBadge(service), preapprovedBadge(service), proratedBadge(service), pausedBadge(service), optionalBadge(service), limitedTimesBadge(service), initialBillingBadge(service, isActiveAgreement, agreementData, now), priceIncreaseBadge(service)]);
}
function optionalBadge(service) {
  return serviceModel.isOptional(service.participation) ? {
    skin: 'warning',
    intent: 'optional',
    text: 'Optional',
    tooltip: 'This service is marked as optional'
  } : null;
}
function pausedBadge(service) {
  return serviceBillingModel.isPaused(service.billing) ? {
    skin: 'warning',
    intent: 'paused',
    text: 'Paused',
    tooltip: 'Automatic billing is paused for this service'
  } : null;
}
function proratedBadge(service) {
  return serviceBillingModel.isProrated(service.billing) ? {
    skin: 'warning',
    intent: 'prorated',
    text: 'Prorated',
    tooltip: 'This service is prorated'
  } : null;
}
function preapprovedBadge(service) {
  const {
    cost
  } = service;
  return serviceCostModel.isVariablePrice(cost) && cost.unitCap && cost.unitCap.gt(0) ? {
    skin: 'success',
    intent: 'preapproved',
    text: "".concat(cost.unitCap.toString(), " ").concat(pluralizeUnitName(cost.unitCap, cost.unitName), " preapproved")
  } : null;
}
function discountBadge(service) {
  const {
    cost
  } = service;
  const discount = cost.discount;
  return discount && discount.amount().gt(0) ? {
    skin: 'success',
    intent: "".concat(discount.type(), "-discount"),
    text: "".concat(discount.format(), " Off")
  } : null;
}
function limitedTimesBadge(service) {
  return serviceModel.maxChargesEnabled(service) ? {
    skin: 'warning',
    intent: 'limit',
    text: "Can be billed ".concat(serviceBillingModel.formatMaxChargesLimit(service.billing)),
    tooltip: 'This service has a limited number of charges'
  } : null;
}
function priceIncreaseBadge(service) {
  return serviceModel.priceIncrease(service).exists() ? {
    skin: 'warning',
    intent: 'price-increase',
    text: 'Auto-price increase'
  } : null;
}
function billingDetailsBadge(service, isActiveAgreement) {
  const {
    billing
  } = service;
  switch (billing.trigger) {
    case BillingTriggerType.OnApproval:
      return isActiveAgreement ? {
        text: 'Billed once, automatically',
        intent: 'type-approval-amendment'
      } : {
        text: 'Billed once, on proposal acceptance',
        intent: 'type-approval'
      };
    case BillingTriggerType.OneTimeManual:
      return {
        text: 'Billed once, manually',
        intent: 'type-onetime-manual'
      };
    case BillingTriggerType.RepeatableManual:
      return {
        text: "Billed ".concat(billing.recurrencePeriod, ", manually"),
        intent: 'type-repeatable-manual'
      };
    case BillingTriggerType.Ongoing:
    default:
      return {
        text: "Billed ".concat(billing.recurrencePeriod, ", automatically"),
        intent: 'type-ongoing'
      };
  }
}
function initialBillingBadge(service, isActiveAgreement, agreementData, now) {
  const {
    billing
  } = service;
  if (serviceBillingModel.isOngoing(billing) && serviceBillingModel.isBilledUpfront(billing) && !serviceBillingModel.isSkipBillingOnAcceptance(billing)) {
    const effectiveDate = agreementData === null || agreementData === void 0 ? void 0 : agreementData.effectiveDate;
    const isFutureEffectiveDate = !!effectiveDate && isAfter(startOfDay(fromDatestampObject(effectiveDate)), startOfDay(now));
    return _objectSpread({
      skin: 'warning'
    }, isActiveAgreement ? {
      intent: 'bill-immediately',
      text: 'Charged immediately',
      tooltip: 'This service will be billed immediately'
    } : isFutureEffectiveDate ? {
      intent: 'bill-on-effective-date',
      text: 'Charged on effective date',
      tooltip: 'This service will be billed on the effective date of the agreement'
    } : {
      intent: 'bill-on-approval',
      text: 'Charged on acceptance',
      tooltip: 'This service will be billed on agreement approval'
    });
  } else {
    return null;
  }
}
export function getStatusBadgeText(serviceType, isActiveAgreement, disableStatusBadge) {
  if (disableStatusBadge) {
    return null;
  }
  switch (serviceType) {
    case ServiceStatusType.Amended:
      return 'Amended';
    case ServiceStatusType.AwaitingApproval:
      return isActiveAgreement ? 'New Service' : null;
    case ServiceStatusType.Completed:
      return 'Completed';
    case ServiceStatusType.Terminated:
      return 'Stopped';
    case ServiceStatusType.Canceled:
      return 'Canceled';
    default:
      return null;
  }
}
export function getDiscountString(discount) {
  const normalized = discount || DiscountModel.makeValue(0);
  return normalized.accept({
    whenPercent: () => "".concat(normalized.amount(), "% Off"),
    whenValue: () => "$".concat(normalized.amount(), " Off")
  });
}
export function getPriceDisplayProps(service) {
  const {
    cost
  } = service;
  const discount = cost.discount;
  const getRangePriceString = (minPrice, maxPrice) => minPrice === null || minPrice.isZero() ? "Up to ".concat(utils.formatDollarAmount(maxPrice)) : "".concat(utils.formatDollarAmount(minPrice), " - ").concat(utils.formatDollarAmount(maxPrice));
  switch (cost.pricingType) {
    case ServiceCostType.Fixed:
      return {
        priceAfterDiscount: utils.formatDollarAmount(utils.applyDiscount(cost.price, discount)),
        priceBeforeDiscount: discount ? utils.formatDollarAmount(cost.price) : undefined
      };
    case ServiceCostType.Variable:
      return {
        priceAfterDiscount: utils.formatDollarAmount(utils.applyDiscount(cost.price, discount)),
        priceBeforeDiscount: discount ? utils.formatDollarAmount(cost.price) : undefined,
        postfixPrice: "/".concat(cost.unitName || 'hr')
      };
    case ServiceCostType.Range:
      {
        const finalMaxPrice = utils.applyDiscount(cost.maxPrice, discount);
        const priceAfterDiscount = getRangePriceString(cost.minPrice ? utils.applyDiscount(cost.minPrice, discount) : null, finalMaxPrice);
        const priceBeforeDiscount = discount ? getRangePriceString(cost.minPrice, cost.maxPrice) : undefined;
        return {
          priceAfterDiscount,
          priceBeforeDiscount
        };
      }
  }
}
export function getPausedBadge(service, isVendor, isActiveAgreement) {
  const {
    status
  } = service;
  return serviceModel.isPaused(service) && (serviceModel.isApproved(status) || serviceModel.isAmended(status) || serviceModel.isAwaitingApproval(status) && isActiveAgreement) ? {
    tooltip: isVendor ? 'Automatic billing is paused for this service. To resume, go to the Billing Hub.' : 'Automatic billing is paused for this service.'
  } : null;
}