// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YuNTm{display:flex;align-items:center;justify-content:center;flex-direction:column;gap:8px;height:100%}.cSMxR{display:flex;align-items:center;justify-content:center;gap:8px;margin-top:56px;flex-wrap:wrap}.K0KDv{max-width:760px;text-align:center}.RbkLC{display:flex;flex-direction:column;gap:36px}.e3eia{display:flex;justify-content:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationActionContainer": `YuNTm`,
	"footer": `cSMxR`,
	"description": `K0KDv`,
	"beforeActionContainer": `RbkLC`,
	"buttonContainer": `e3eia`
};
export default ___CSS_LOADER_EXPORT___;
