function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import Decimal from 'decimal.js';
import { relationshipDisplayedTitle } from "../business-logic/relationshipLogic";
export class FutureInvoiceModel {
  static fromDTO(dto) {
    return new FutureInvoiceModel(dto);
  }
  constructor(data) {
    this.data = _objectSpread(_objectSpread({}, data), {}, {
      rawTotal: new Decimal(data.rawTotal)
    });
  }
  compoundId() {
    return "".concat(this.data.relationshipId, "-").concat(this.data.issueDate);
  }
  relationshipId() {
    return this.data.relationshipId;
  }
  companyName() {
    return this.data.companyName;
  }
  agreementName() {
    return this.data.agreementName;
  }
  displayedAgreementName() {
    return relationshipDisplayedTitle(this.data.agreementName, this.data.companyName);
  }
  dueDate() {
    return this.data.dueDate;
  }
  issueDate() {
    return this.data.issueDate;
  }
  requiresApproval() {
    return this.data.requiresApproval;
  }
  total() {
    return this.data.total;
  }
  rawTotal() {
    return this.data.rawTotal;
  }
  rawIssueDate() {
    return this.data.rawIssueDate;
  }
  rawDueDate() {
    return this.data.rawDueDate;
  }
}