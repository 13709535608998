import { BillingType, InitialDiscount } from "./BillClientModal.types";
import { serviceCostModel } from "../../models";
import { utils } from "../../utils";
import { toDatestampObject } from "../../models/time";
import { discountToDTO } from "./BillClientModal.utils";
import { generateUniqueId } from 'seagull/utils/generateUniqueId';
export function createEmptyServiceItem(service) {
  return {
    itemId: generateUniqueId('service'),
    type: BillingType.Service,
    note: '',
    service: {
      id: service.id,
      price: '',
      quantity: '',
      cost: service.cost
    },
    fullService: service
  };
}
export function createEmptyChargeItem() {
  return {
    itemId: generateUniqueId('charge'),
    type: BillingType.Charge,
    note: '',
    charge: {
      service: null,
      quantity: '',
      rate: '',
      discount: InitialDiscount
    }
  };
}
export const mapItemToCharge = item => {
  const {
    service,
    rate,
    quantity,
    discount
  } = item.charge;
  const note = item.note;
  if (!service) {
    throw new Error('Service is required');
  }
  return {
    name: service.label,
    serviceTemplateId: service.value === '' ? undefined : service.value,
    note,
    rate: utils.dollarsToCents(+rate),
    quantity: utils.dollarsToCents(+quantity),
    discount: discountToDTO(discount)
  };
};
export const mapItemToService = item => {
  const {
    id,
    quantity,
    cost,
    price
  } = item.service;
  const {
    note
  } = item;
  return {
    serviceId: id,
    quantity: serviceCostModel.isVariablePrice(cost) && quantity ? utils.dollarsToCents(+quantity) : undefined,
    price: serviceCostModel.isRangePrice(cost) ? utils.dollarsToCents(+price) : undefined,
    note: note || undefined
  };
};
export const getSubmitChargesData = data => {
  const {
    issueDate,
    items
  } = data;
  const charges = items.filter(item => item.type === BillingType.Charge).map(item => mapItemToCharge(item));
  const services = items.filter(item => item.type === BillingType.Service).map(item => mapItemToService(item));
  return {
    requestedBillingTime: toDatestampObject(issueDate),
    charges,
    servicesToBill: services
  };
};