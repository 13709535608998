// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hAvwz{text-align:left}.mHldE{display:flex;flex-direction:column;gap:16px;margin:24px 0 0 0}.SIaWU{display:flex;align-items:center;gap:16px;border:1px solid #e0e0e0;border-radius:8px;padding:10px;padding:16px;justify-content:space-between}.SIaWU .RqIso{display:flex;align-items:center;gap:16px}.SIaWU .p1L0u{width:200px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `hAvwz`,
	"buttons": `mHldE`,
	"MailButton": `SIaWU`,
	"MailButtonImage": `RqIso`,
	"ComingSoonText": `p1L0u`
};
export default ___CSS_LOADER_EXPORT___;
