import rangeRight from 'lodash/rangeRight';
import { addMonths, startOfMonth, subMonths } from 'date-fns';
import range from 'lodash/range';

// including current month
export function getLastXMonths(now, num) {
  const startOfThisMonth = startOfMonth(now);
  return rangeRight(num).map(numToSub => subMonths(startOfThisMonth, numToSub));
}

// excluding current month
export const getNextXMonths = (now, num) => {
  const startOfThisMonth = addMonths(startOfMonth(now), 1);
  return range(num).map(numToAdd => addMonths(startOfThisMonth, numToAdd));
};