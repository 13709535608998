import { IntegrationStatus } from "../../types/api.model";
export function isLinked(integration) {
  return integration.status === IntegrationStatus.Linked;
}
export function isErrored(integration) {
  return integration.status === IntegrationStatus.Error;
}
export function automaticInvoicesSync(integration) {
  return integration.settings.automaticInvoicesSync;
}
export function displayBillableTimeActivities(integration) {
  return integration.settings.displayBillableTimeActivities;
}
export function classTrackingAgreementOverride(integration) {
  return integration.settings.classTrackingAgreementOverride;
}