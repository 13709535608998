import { useEffect, useMemo, useState } from 'react';
import { useStateInRef } from 'seagull';
import { useAppConfig } from "../store/store-context";
export const defaultPageSize = 100;
export const defaultScrollPadding = 100;
export const useInfiniteScrollDataAttribute = 'data-useinfinitescroll';
/**
 * Adds infinite-scroll behavior to the given data. Will split the data to pages, and add
 * the next page whenever the user scrolls down to the bottom of the scroll element.
 * Put the 'onScroll' handler on your scroll element.
 * Use the 'paginatedData' to render the rows.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useInfiniteScroll = options => {
  const {
    infiniteScrollPageSize
  } = useAppConfig();
  const {
    data,
    scrollElRef
  } = options;
  const pageSize = options.pageSize || infiniteScrollPageSize || defaultPageSize;
  const scrollPadding = options.scrollPadding || defaultScrollPadding;
  const getOptions = useStateInRef(Object.assign(options, {
    pageSize,
    scrollPadding
  }));
  const [page, setPage] = useState(() => getMinPage());
  useEffect(() => {
    // Reset pagination and scroll.
    const scrollEl = scrollElRef && 'current' in scrollElRef ? scrollElRef.current : scrollElRef;
    if (scrollEl) {
      scrollEl.scrollTop = 0;

      // Add unique data attribute so we can assert the component actually uses this hook in tests.
      if (!scrollEl.getAttribute(useInfiniteScrollDataAttribute)) {
        scrollEl.setAttribute(useInfiniteScrollDataAttribute, 'true');
      }
    }
    setPage(getMinPage());
  }, [data, scrollElRef]);
  function getMinPage() {
    const {
      data,
      pageSize,
      alwaysRenderIndex: alwaysRenderIndex
    } = getOptions();
    const idx = alwaysRenderIndex ? alwaysRenderIndex(data) : 0;
    const numItemsToRender = Math.max(idx, 0) + 1;
    return Math.ceil(numItemsToRender / pageSize);
  }
  return useMemo(() => ({
    paginatedData: data.slice(0, page * pageSize),
    onScroll(e) {
      const hasMoreData = page * pageSize < data.length;
      if (!hasMoreData) {
        return;
      }
      const {
        scrollTop,
        clientHeight,
        scrollHeight
      } = e.target;
      if (scrollTop >= scrollHeight - clientHeight - scrollPadding) {
        setPage(page + 1);
      }
    }
  }), [data, pageSize, page, scrollPadding]);
};