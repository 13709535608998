import { BillMode } from "../BillClientModal/BillClientModal.types";
export function getChargeSuccessToastText(mode) {
  switch (mode) {
    case BillMode.Now:
      return 'The charge was billed successfully';
    case BillMode.Upcoming:
      return 'The charge was added to the upcoming bill';
    case BillMode.FutureDate:
      return 'The charge was added to a future date';
  }
}