import { isEqual } from 'lodash';
import { DateRange, SearchFilterType } from "../types";
export const makeInitialFilterValue = filterConfig => {
  switch (filterConfig.type) {
    case SearchFilterType.MultiSelectFilter:
      return [];
    case SearchFilterType.DateRangeFilter:
      return {
        from: undefined,
        to: undefined
      };
    case SearchFilterType.RadioFilter:
      return filterConfig.defaultOption;
    case SearchFilterType.DateRangeWithPresets:
      return {
        dateRange: [],
        dateRangeOption: DateRange.Last12Months
      };
  }
};
export const isInitialFilterValue = (filterValue, filterConfig) => isEqual(filterValue, makeInitialFilterValue(filterConfig));