import { RemainingCreditReportSortColumnId } from 'anchor-scout/types';
import { SearchFilterType } from "../components/types";
import { FilterType } from "./types";
export const makeCsv = (headerRow, entries) => [headerRow].concat(entries.map(entry => [entry.customerName, entry.agreementName, entry.serviceName, entry.rawCredit.toString()]));
export const remainingCreditReportHeaderColumnDefsForExport = [{
  value: 'Company name'
}, {
  value: 'Agreement name'
}, {
  value: 'Service name'
}, {
  value: 'Credit'
}];
export const headerItems = [{
  value: 'Company name',
  sortId: RemainingCreditReportSortColumnId.CompanyName
}, {
  value: 'Agreement name',
  sortId: RemainingCreditReportSortColumnId.AgreementName
}, {
  value: 'Service name',
  sortId: RemainingCreditReportSortColumnId.ServiceName
}, {
  value: 'Credit',
  sortId: RemainingCreditReportSortColumnId.Amount
}];
export const sortIds = headerItems.map(item => item.sortId);
export const defaultSort = {
  colIndex: sortIds.indexOf(RemainingCreditReportSortColumnId.CompanyName),
  sort: 'desc'
};
export const remainingCreditReportColumnDefs = [{
  width: 234,
  sortable: true
}, {
  width: 234,
  sortable: true
}, {
  width: 234,
  sortable: true
}, {
  width: 148,
  sortable: true,
  hAlign: 'end'
}];
export const filterLabels = {
  [FilterType.CustomerName]: 'Company name',
  [FilterType.AgreementName]: 'Agreement name',
  [FilterType.ServiceName]: 'Service name'
};
export const makeFiltersConfig = (serviceNames, customerNames, agreementNames) => ({
  [FilterType.CustomerName]: {
    type: SearchFilterType.MultiSelectFilter,
    options: customerNames,
    withSearchbox: true,
    removable: true
  },
  [FilterType.ServiceName]: {
    type: SearchFilterType.MultiSelectFilter,
    options: serviceNames,
    withSearchbox: true,
    removable: true
  },
  [FilterType.AgreementName]: {
    type: SearchFilterType.MultiSelectFilter,
    options: agreementNames,
    withSearchbox: true,
    removable: true
  }
});