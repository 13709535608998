import { serviceTemplateModel } from "../../models";
import { utils } from "../../utils";
import { composeValidators, getTitleValidator, titleValidation } from 'validators';
export const baseColumnDefs = [{
  width: [200, 400],
  sortable: true
},
// service name
{
  width: 130,
  sortable: true
},
// price
{
  width: 120,
  sortable: true
},
// frequency
{
  width: 110,
  sortable: true
} // billing
];
export const baseHeaderItems = [{
  value: 'Service name'
}, {
  value: 'Rate'
}, {
  value: 'Billing occurrence'
}, {
  value: 'Billing trigger'
}];
export const defaultSort = {
  colIndex: 0,
  sort: 'desc'
};
export const sortIds = baseHeaderItems.map(item => item.value);
export async function saveServiceTemplate(_ref) {
  let {
    listener,
    modifiedService,
    originalService,
    repo,
    storePopulator
  } = _ref;
  listener.saveStarted();
  try {
    if (originalService) {
      await repo.update(modifiedService);
    } else {
      await repo.create(modifiedService);
    }
    await storePopulator.populateServiceTemplates();
  } catch (err) {
    listener.saveFailed();
    throw err;
  } finally {
    listener.saveFinished();
  }
}
function sortByName(a, b) {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();
  return aName < bName ? -1 : aName > bName ? 1 : 0;
}
function sortByPrice(a, b) {
  const [aPrice, bPrice] = [a, b].map(service => utils.applyDiscount(serviceTemplateModel.isFixedPrice(service.cost) ? service.cost.price : serviceTemplateModel.isVariablePrice(service.cost) ? service.cost.price : service.cost.minPrice != null ? service.cost.minPrice : service.cost.maxPrice, service.cost.discount));
  return aPrice.minus(bPrice).toNumber();
}
function sortByFrequency(a, b) {
  const [aFrequency, bFrequency] = [a, b].map(service => serviceTemplateModel.isRecurring(service.billing) ? service.billing.recurrencePeriod : 'one');
  return aFrequency < bFrequency ? -1 : aFrequency > bFrequency ? 1 : 0;
}
function sortByBilling(a, b) {
  const [aBilling, bBilling] = [a, b].map(service => serviceTemplateModel.isManual(service.billing) ? 'manual' : 'automatic');
  return aBilling < bBilling ? -1 : aBilling > bBilling ? 1 : 0;
}
function sortByQboStatus(a, b, qboItemsById) {
  var _qboItemsById$a$integ, _qboItemsById$b$integ;
  if (!a.integratedId) return b.integratedId ? 1 : 0;
  if (!b.integratedId) return -1;
  const aName = (_qboItemsById$a$integ = qboItemsById[a.integratedId]) === null || _qboItemsById$a$integ === void 0 ? void 0 : _qboItemsById$a$integ.name.toLowerCase();
  const bName = (_qboItemsById$b$integ = qboItemsById[b.integratedId]) === null || _qboItemsById$b$integ === void 0 ? void 0 : _qboItemsById$b$integ.name.toLowerCase();
  return aName < bName ? -1 : aName > bName ? 1 : 0;
}
export const sortFunctions = [isAsc => (a, b) => (isAsc ? -1 : 1) * sortByName(a, b), isAsc => (a, b) => (isAsc ? -1 : 1) * sortByPrice(a, b) || sortByName(a, b), isAsc => (a, b) => (isAsc ? -1 : 1) * sortByFrequency(a, b) || sortByName(a, b), isAsc => (a, b) => (isAsc ? -1 : 1) * sortByBilling(a, b) || sortByName(a, b), (isAsc, qboItemsById) => (a, b) => (isAsc ? -1 : 1) * sortByQboStatus(a, b, qboItemsById) || sortByName(a, b)];
function isUniqueServiceName(_ref2) {
  let {
    serviceName,
    skipServiceId,
    allServices
  } = _ref2;
  return !allServices || allServices.every(service => service.name !== serviceName || service.id === skipServiceId);
}
function validateServiceName(_ref3) {
  let {
    serviceName,
    skipServiceId,
    allServices
  } = _ref3;
  if (!serviceName) {
    return 'No service name';
  }
  if (!isUniqueServiceName({
    serviceName,
    allServices,
    skipServiceId
  })) {
    return 'Provide a unique service name';
  }
  return true;
}
export function getServiceNameValidation(_ref4) {
  let {
    skipServiceId,
    allServices
  } = _ref4;
  return composeValidators([titleValidation('Service name', {
    required: 'Please enter a service name'
  }), {
    validate: {
      serviceName: _serviceName => validateServiceName({
        serviceName: _serviceName,
        skipServiceId,
        allServices
      })
    }
  }]);
}
export function getDuplicatedService(_ref5) {
  let {
    service,
    serviceNames
  } = _ref5;
  const suffix = ' new';
  const newService = serviceTemplateModel.clone(service);
  const titleValidator = getTitleValidator();
  const getIsValid = () => titleValidator(newService.name) === true;
  const getIsNotUnique = () => serviceNames.some(name => name === newService.name);

  // Verify uniqueness and keep adding 'new's until a unique name is received.
  do {
    newService.name += suffix;
  } while (getIsNotUnique());

  // Truncate the name if it is too long.
  let isValid = getIsValid();
  let isNotUnique = getIsNotUnique();
  while (!isValid || isNotUnique) {
    const match = newService.name.match(new RegExp(suffix));
    const suffixIndex = match === null || match === void 0 ? void 0 : match.index;
    if (suffixIndex == null) throw new Error('Suffix not found in service name');
    // Remove last char before ' new' suffix.
    newService.name = newService.name.slice(0, suffixIndex - 1) + newService.name.slice(suffixIndex);
    isValid = getIsValid();
    isNotUnique = getIsNotUnique();
  }
  return newService;
}
export function getDuplicatedServices(services, allServices) {
  const duplicatedServices = [];
  services.forEach(service => {
    const serviceNames = duplicatedServices.concat(allServices || []).map(s => s.name);
    duplicatedServices.push(getDuplicatedService({
      service,
      serviceNames
    }));
  });
  return duplicatedServices;
}