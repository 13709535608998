import { addDays, format, max, startOfDay } from 'date-fns';
import { BillMode } from "../BillClientModal.types";
import compact from 'lodash/compact';
import { getMaxFutureInvoiceDate } from "../../../business-logic/billingLogic";
import { relationshipModel, timeModel } from "../../../models";
import { sendErrReport } from "../../../infra/utils";
export function getMinDateMessage(minDate) {
  return minDate ? "Date should not be before ".concat(format(minDate, 'LLL do, Y')) : undefined;
}
export function getMaxDateMessage(maxDate) {
  return maxDate ? "Date should not be after ".concat(format(maxDate, 'LLL do, Y')) : undefined;
}
export const selectorWidth = '320px';
export const ctaTextMap = {
  now: 'Bill now',
  upcoming: 'Add to bill',
  futureDate: 'Bill'
};
export function getDateRange(mode, now, effectiveDate, earliestBillableDate) {
  switch (mode) {
    case BillMode.Upcoming:
      return {};
    case BillMode.Now:
      {
        const today = startOfDay(now);
        return {
          maxDate: today,
          minDate: earliestBillableDate
        };
      }
    case BillMode.FutureDate:
      {
        return {
          minDate: max(compact([effectiveDate, addDays(startOfDay(now), 1)])),
          maxDate: getMaxFutureInvoiceDate(now)
        };
      }
  }
}
export function getDefaultIssueDate(mode, invoiceDateInfo, now, effectiveDate, earliestBillableDate) {
  switch (mode) {
    case BillMode.Now:
      return startOfDay(now);
    case BillMode.Upcoming:
      return invoiceDateInfo !== null && invoiceDateInfo !== void 0 && invoiceDateInfo.invoiceDraftDates.dates().length ? invoiceDateInfo === null || invoiceDateInfo === void 0 ? void 0 : invoiceDateInfo.invoiceDraftDates.dates()[0] : startOfDay(now);
    case BillMode.FutureDate:
      {
        if (invoiceDateInfo && !invoiceDateInfo.isUpcomingSelected) {
          return invoiceDateInfo.selectedDate;
        } else {
          const {
            minDate
          } = getDateRange(mode, now, effectiveDate, earliestBillableDate);
          if (!minDate) {
            const error = new Error('no default issueDate');
            sendErrReport(error);
            throw error;
          }
          return minDate;
        }
      }
  }
}
export function normalizeEffectiveDate(effectiveDate) {
  return !effectiveDate ? null : effectiveDate instanceof Date ? effectiveDate : timeModel.fromDatestampObject(effectiveDate);
}
export function getBillModeOptions(now, effectiveDate, invoiceDateInfo) {
  return compact([{
    value: BillMode.Upcoming,
    text: 'Add to upcoming invoice',
    disabled: !invoiceDateInfo,
    tooltip: !invoiceDateInfo ? 'There is no upcoming invoice' : undefined
  }, !relationshipModel.isFutureRelationship({
    effectiveDate
  }, now) && {
    value: BillMode.Now,
    text: 'Bill now'
  }, {
    value: BillMode.FutureDate,
    text: 'Bill on date'
  }]);
}
export function getDefaultBillMode(billModeOptions, invoiceDateInfo) {
  if (invoiceDateInfo !== null && invoiceDateInfo !== void 0 && invoiceDateInfo.isUpcomingSelected && hasEnabledBillModeOption(billModeOptions, BillMode.Upcoming)) {
    return BillMode.Upcoming;
  } else if (invoiceDateInfo && !invoiceDateInfo.isUpcomingSelected && hasEnabledBillModeOption(billModeOptions, BillMode.FutureDate)) {
    return BillMode.FutureDate;
  } else if (hasEnabledBillModeOption(billModeOptions, BillMode.Now)) {
    return BillMode.Now;
  } else {
    const firstEnabledOption = billModeOptions.find(opt => !opt.disabled);
    if (!firstEnabledOption) {
      const error = new Error('no enabled billMode options');
      sendErrReport(error);
      throw error;
    }
    return firstEnabledOption.value;
  }
}
function hasEnabledBillModeOption(billModeOptions, billMode) {
  return billModeOptions.some(opt => opt.value === billMode && !opt.disabled);
}