import { isSameDay } from 'date-fns';
import { sortAlphabetically } from "../../../infra/utils";
import { DiscountType } from "../../../models/DiscountModel";
export const validateGreaterThan0 = value => value && +value > 0;
export function getTableInfo(discount) {
  const tableInfo = [{
    headerItem: {
      value: 'Service name'
    },
    columnDef: {
      width: 230
    }
  }, {
    headerItem: {
      value: 'Quantity'
    },
    columnDef: {
      width: 110
    }
  }, {
    headerItem: {
      value: 'Rate'
    },
    columnDef: {
      width: 150
    }
  }, {
    headerItem: {
      value: discount.type === DiscountType.Percent ? 'Discount' : 'Unit discount'
    },
    columnDef: {
      width: 140
    }
  }, {
    headerItem: {
      value: 'Total'
    },
    columnDef: {
      width: [140, 230]
    }
  }];
  return {
    columnDefs: tableInfo.map(i => i.columnDef),
    headerItems: tableInfo.map(i => i.headerItem)
  };
}
export function getServiceTemplateOptions(serviceTemplates, isLoading) {
  if (isLoading) return [];
  const options = serviceTemplates.map(st => ({
    value: st.id,
    label: st.name
  }));
  return sortAlphabetically(options, 'label', {
    ascending: true,
    ignoreCase: true
  });
}
export function getClientApprovalCalloutText(issueDate, invoiceDateInfo) {
  return invoiceDateInfo !== null && invoiceDateInfo !== void 0 && invoiceDateInfo.invoiceDraftDates.dates().some(d => isSameDay(d, issueDate)) ? 'Adding an out-of-agreement charge to an existing invoice will require your client to manually approve the payment for the entire invoice' : 'Billing your client with out-of-agreement charges will require your client to manually approve the payment';
}