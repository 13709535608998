function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { createStore as createStoreVanilla } from 'zustand';
import { devtools } from 'zustand/middleware';
import { getDefaultInitialState } from "./store.utils";
import { initStoreActions } from "./store-actions";
import { initSendRequest } from "./sendRequest";
// Don't attach the devtools middleware in production, unless a specific URL query-param is provided.
// Otherwise attach, so we can debug the store with the redux-devtools chrome extension.
const disableDevtools = (IS_CYPRESS || IS_PRODUCTION) && !location.search.includes('enableZustandDevtools');
export function createStore(_ref) {
  let {
    initialState,
    session,
    onLogout,
    getNow,
    initStoreApi,
    slackClient,
    reportToSentry,
    getCookie,
    onCookieChange,
    onLogBI
  } = _ref;
  const initializer = (setState, getState) => {
    const sendRequest = initSendRequest({
      slackClient,
      getState,
      onLogout,
      getCookie,
      onCookieChange,
      onReportToSentry: _ref2 => {
        let {
          message,
          requestId,
          method,
          url,
          responseBody
        } = _ref2;
        return reportToSentry(message, {
          context: {
            '** Failed Request Data **': {
              requestId,
              method,
              url,
              responseBody
            }
          }
        });
      }
    });
    const api = initStoreApi({
      getState,
      getNow,
      sendRequest
    });
    const actions = initStoreActions({
      api,
      setState,
      getState,
      onReportToSentry: reportToSentry,
      onLogBI,
      getNow
    });
    return _objectSpread(_objectSpread(_objectSpread({}, getDefaultInitialState(session)), initialState), {}, {
      actions
    });
  };
  return disableDevtools ? createStoreVanilla(initializer) : createStoreVanilla(devtools(initializer));
}