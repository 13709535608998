import Decimal from 'decimal.js';
import { timeModel } from '.';
export class ServiceInvoiceModel {
  static fromDTO(dto) {
    return new ServiceInvoiceModel({
      id: dto.id,
      dueDate: dto.dueDate,
      formattedNumber: dto.formattedNumber,
      pdfFile: dto.pdfFile,
      invoices: dto.invoices || [],
      quantity: new Decimal(dto.quantity),
      totalAmount: new Decimal(dto.totalAmount)
    });
  }
  constructor(data) {
    this.data = data;
  }
  id() {
    return this.data.id;
  }
  formattedDueDate() {
    return timeModel.formatDate(new Date(this.data.dueDate));
  }
  formattedNumber() {
    return this.data.formattedNumber;
  }
  quantity() {
    return this.data.quantity;
  }
  total() {
    return this.data.totalAmount;
  }
  pdfFile() {
    return this.data.pdfFile;
  }
  isPdfFileDone() {
    return this.data.pdfFile.status === 'done';
  }
  invoices() {
    return this.data.invoices;
  }
}