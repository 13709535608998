function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import compact from 'lodash/compact';
import { extractClaimsFromToken } from "../infra/utils";
import { Base64 } from 'js-base64';
export class RealCookiesManager {
  constructor(authDomain, localStorage) {
    _defineProperty(this, "authTokenFromCookieKey", 'authTokenFromCookie');
    this.authDomain = authDomain;
    this.localStorage = localStorage;
  }
  getCookie() {
    return document.cookie;
  }
  setCookie(key, value) {
    let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    document.cookie = compact(["".concat(key, "=").concat(value), opts.expires && "expires=".concat(opts.expires.toUTCString()), 'path=/', this.authDomain && "domain=".concat(this.authDomain)]).join('; ');
  }
  getCookieByName(name) {
    const cookieMap = this.getCookieMap();
    return cookieMap[name] || null;
  }
  getDecodedCookieByName(name) {
    const originalCookie = this.getCookieByName(name);
    if (!originalCookie) {
      return null;
    }
    return Base64.decode(originalCookie);
  }
  getUserEmailFromUserDetailsCookie() {
    const decodedCookie = this.getDecodedCookieByName('last_login_user_details');
    if (!decodedCookie || !decodedCookie.includes('email')) {
      return null;
    }
    const userDetails = JSON.parse(decodedCookie);
    return userDetails.email;
  }
  getHasVendorAccountFromUserDetailsCookie() {
    const decodedCookie = this.getDecodedCookieByName('last_login_user_details');
    if (!decodedCookie || !decodedCookie.includes('hasVendorAccount')) {
      return null;
    }
    const userDetails = JSON.parse(decodedCookie);
    return userDetails.hasVendorAccount;
  }
  getAuthToken() {
    const cookieMap = this.getCookieMap();
    return cookieMap.AuthToken || null;
  }
  getClaims() {
    return extractClaimsFromToken(this.getAuthToken());
  }
  trySignalAuthTokenChange() {
    var _this$getClaims;
    const authToken = this.getAuthToken();
    const prevAuthToken = this.localStorage.getItem(this.authTokenFromCookieKey);
    if (authToken === prevAuthToken) {
      return;
    }
    if (!authToken) {
      this.localStorage.removeItem(this.authTokenFromCookieKey);
    } else if (!((_this$getClaims = this.getClaims()) !== null && _this$getClaims !== void 0 && _this$getClaims.setPasswordOnly)) {
      this.localStorage.setItem(this.authTokenFromCookieKey, authToken);
    }
  }
  didAuthTokenChange(event) {
    var _this$getClaims2;
    return event.key === this.authTokenFromCookieKey && event.oldValue !== event.newValue && !((_this$getClaims2 = this.getClaims()) !== null && _this$getClaims2 !== void 0 && _this$getClaims2.setPasswordOnly);
  }
  getCookieMap() {
    return document.cookie.split(';').reduce((a, b) => {
      const [key, val] = b.split('=');
      a[key.trim()] = val;
      return a;
    }, {});
  }
}
const noop = () => {};
const nullFn = () => null;
export const mockCookiesManager = {
  getCookie: () => '',
  setCookie: noop,
  getCookieByName: nullFn,
  getAuthToken: nullFn,
  getClaims: nullFn,
  trySignalAuthTokenChange: noop,
  didAuthTokenChange: () => false,
  getUserEmailFromUserDetailsCookie: nullFn,
  getDecodedCookieByName: nullFn,
  getHasVendorAccountFromUserDetailsCookie: nullFn
};