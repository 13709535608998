import { BillMode } from "../../../../components/BillClientModal/BillClientModal.types";
export function getServiceSuccessToastText(mode, serviceName) {
  switch (mode) {
    case BillMode.Now:
      return "Service ".concat(serviceName, " billed successfully");
    case BillMode.Upcoming:
      return "Service ".concat(serviceName, " was added to the upcoming bill");
    case BillMode.FutureDate:
      return "Service ".concat(serviceName, " was added to a future date");
  }
}
export function getBillServiceFailureToastText(mode, serviceName) {
  switch (mode) {
    case BillMode.Now:
      return "Failed to bill service ".concat(serviceName);
    case BillMode.Upcoming:
      return "Failed to add service ".concat(serviceName, " to the upcoming bill");
    case BillMode.FutureDate:
      return "Failed to add service ".concat(serviceName, " to a future date");
  }
}